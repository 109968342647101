<template>
  <div>
    <form class="mb-2">
      <div class="row">
        <div class="col-md-10">
          <div class="form-group">
            <Select
              v-model="form.category_id"
              filterable
              :class="{
                'is-invalid border-danger': form.errors.has('category_id'),
              }"
              name="category_id"
              id="category_id"
              placeholder="Select Category"
            >
              <Option
                :value="category.id"
                v-for="(category, index) in main_categories"
                :key="index"
                v-if="main_categories.length"
                >{{ category.name }}</Option
              >
            </Select>
            <has-error :form="form" field="category_id"></has-error>
          </div>
        </div>
        <div class="col-md-2">
          <Button
            type="primary"
            class="mr-2"
            :disabled="form.busy"
            @click="addCategory"
          >
            Add
          </Button>
          <Button @click="Close"> Close </Button>
        </div>
      </div>
    </form>
    <div>
      <draggable
        @change="rearrangeCategories"
        v-model="categories"
        class="list-group list-group-flush cursor-move"
        tag="ul"
      >
        <transition-group type="transition" name="flip-list">
          <b-list-group-item
            v-for="category in categories"
            :key="category.id"
            tag="li"
          >
            <div class="d-flex justify-content-between">
              <div>
                <b>{{ category.order_by }} . </b>
                <span v-if="category.category">{{
                  category.category.name
                }}</span>
                <span class="badge badge-success ml-1">{{
                  category.trendy_courses_count
                }}</span>
              </div>
              <div>
                <button
                  class="btn btn-success btn-sm mr-1"
                  @click="getCourseModel(category.id, category.category_id)"
                >
                  <feather-icon size="16" icon="PlusIcon" />
                </button>
                <button
                  class="btn btn-danger btn-sm"
                  @click="Delete(category.id)"
                >
                  <feather-icon icon="Trash2Icon" />
                </button>
              </div>
            </div>
          </b-list-group-item>
        </transition-group>
      </draggable>
    </div>

    <Modal
      title="Course Setup"
      v-model="add_course_model"
      :mask-closable="false"
      :closable="false"
      :footer-hide="true"
      width="1200px"
    >
      <form class="mb-2">
        <div class="row">
          <div class="col-md-10">
            <div class="form-group">
              <Select
                v-model="form.course_id"
                filterable
                :class="{
                  'is-invalid border-danger': form.errors.has('course_id'),
                }"
                name="course_id"
                id="course_id"
                placeholder="Select Course"
              >
                <Option
                  :value="course.id"
                  v-for="(course, index) in category_courses"
                  :key="index"
                  v-if="category_courses.length"
                  >{{ course.name }}</Option
                >
              </Select>
              <has-error :form="form" field="course_id"></has-error>
            </div>
          </div>
          <div class="col-md-2">
            <Button
              type="primary"
              class="mr-2"
              :disabled="form.busy"
              @click="addCourse"
            >
              Add
            </Button>
            <Button @click="Close"> Close </Button>
          </div>
        </div>
      </form>
      <div>
        <draggable
          @change="rearrangeCourses"
          v-model="courses"
          class="list-group list-group-flush cursor-move"
          tag="ul"
        >
          <transition-group type="transition" name="flip-list">
            <b-list-group-item
              v-for="course in courses"
              :key="course.id"
              tag="li"
            >
              <div class="d-flex justify-content-between">
                <div>
                  <b>{{ course.order_by }} . </b>
                  <span v-if="course.course">{{ course.course.name }}</span>
                </div>
                <div>
                  <button
                    class="btn btn-danger btn-sm"
                    @click="Delete(course.id)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </button>
                </div>
              </div>
            </b-list-group-item>
          </transition-group>
        </draggable>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BButton,
  BFormCheckbox,
  BFormCheckboxGroup,
  BListGroupItem,
} from "bootstrap-vue";
import { Button, HasError, AlertError } from "vform/src/components/bootstrap5";
import draggable from "vuedraggable";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
export default {
  data() {
    return {
      form: new Form({
        trendy_course_id: null,
        category_id: null,
        course_id: null,
      }),
      categories: [],
      courses: [],
      add_course_model: false,
    };
  },
  mounted() {
    this.getResults();
    this.$store.dispatch("getMainCategory");
  },
  methods: {
    async getResults() {
      const categories = await this.callApi(
        "get",
        "/app/cms/get/trendy/category"
      );
      if (categories.status == 200) {
        this.categories = categories.data.categories;
      }
    },
    getCourseModel(id, category_id) {
      this.form.trendy_course_id = id;
      this.form.category_id = category_id;
      this.getCourse();
      this.$store.dispatch("getCategoryWiseCourses", category_id);
      this.add_course_model = true;
    },
    async getCourse() {
      const courses = await this.callApi(
        "get",
        "/app/cms/get/trendy/course/" + this.form.trendy_course_id
      );
      if (courses.status == 200) {
        this.courses = courses.data.courses;
      }
    },
    addCategory() {
      this.form.trendy_course_id = null;
      this.form.course_id = null;
      this.form
        .post("/app/cms/store/trendy/category")
        .then(({ data }) => {
          this.Close();
          this.s(data.message);
          this.$Loading.finish();
        })
        .catch((e) => {
          this.$Loading.error();
        });
    },
    addCourse() {
      this.form
        .post("/app/cms/store/trendy/course")
        .then(({ data }) => {
          this.form.course_id = null;
          this.getCourse();
          this.s(data.message);
          this.$Loading.finish();
        })
        .catch((e) => {
          this.$Loading.error();
        });
    },
    async rearrangeCategories() {
      const categories = await this.callApi(
        "post",
        "/app/cms/rearrange/trendy/course",
        { data: this.categories }
      );
      if (categories.status == 200) {
        this.getResults();
        this.s(categories.data.message);
      }
    },
    async rearrangeCourses() {
      const categories = await this.callApi(
        "post",
        "/app/cms/rearrange/trendy/course",
        { data: this.courses }
      );
      if (categories.status == 200) {
        this.getCourse();
        this.s(categories.data.message);
      }
    },
    Close() {
      this.form.id = null;
      this.form.trendy_course_id = null;
      this.form.category_id = null;
      this.form.course_id = null;
      this.getResults();
      this.add_course_model = false;
    },

    Delete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't to delete it!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.DeleteAlert("/app/cms/delete/trendy/course/" + id);
          if (this.form.trendy_course_id != null) {
            this.getCourse();
          }
          {
            this.getResults();
          }
        }
      });
    },
  },
  components: {
    BListGroupItem,
    draggable,
    Prism,
    BCard,
    BCardBody,
    BButton,
    BFormCheckbox,
    BFormCheckboxGroup,
    HasError,
    AlertError,
  },
  computed: {
    main_categories() {
      return this.$store.getters.getMainCategory;
    },
    category_courses() {
      return this.$store.getters.getCategoryWiseCourses;
    },
  },
};
</script>

<style></style>
